@charset "utf-8";

.c-drawer {
  height: 100%;
  position: fixed;
  z-index: index($zindex, drawer);
  top: 0;
  right: 0;
  width: calc(100vw - 63px);
  background-color: $color-white;
  transition: $slide-in-out-transition;
  @include shadow-drawer;

  &--invisible {
    right: -100%;
    transition: $slide-in-out-transition;
  }

  &__inner {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__links {
    border-top: none;
  }

  &__links:first-child {
    padding: $space-m 0 $space-l;
  }

  &__links--login {
    padding: $space-m 0;
  }

  &__links--middle {
    padding-bottom: $space-m;
  }

  &__links--large {
    padding-bottom: $space-l;
  }

  &__link {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    color: $color-grey-0;
    line-height: 1.25;
    padding: $space-m $space-xl;
    display: block;
  }

  &__balloon {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    color: $color-grey-0;
    line-height: 1.25;
    padding: $space-l $space-xl 0;
    display: block;
  }

  &__link--login {
    position: relative;
    padding-left: $space-3xl;
  }

  &__button {
    padding: $space-xl $space-xl $space-m;
  }

  &__icon {
    @include icon-m;
    fill: $color-primary;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $space-l;
  }

  &__icon--lock {
    @include icon-l;
    vertical-align: text-bottom;
  }

  &__close-btn {
    position: absolute;
    top: $space-m;
    left: -$space-3xl;
  }
}
