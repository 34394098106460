@charset "utf-8";

.c-offers-top {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__title {
    @include title-m;
    font-weight: $font-weight-normal;
    margin-top: 0;
    font-size: 16px;
    width: 100%;
  }

  &__title\@desktop {
    margin-top: 0;
  }

  &__title-strong {
    @include title-xl;
  }

  &__title-strong\@desktop {
    @include title-2xl;
  }

  &__address {
    font-weight: 300;
    font-size: $font-size-s;
    line-height: 16px;
    margin-top: $space-xs;
  }

  &__tag\@mobile {
    @include mq($until: desktop) {
      position: absolute;
      top: -$space-s;
    }
  }

  &__image-column {
    height: 212px;
  }

  &__image-column\@desktop {
    @include mq($from: desktop) {
      width: 631px;
      height: 353px;
    }
  }

  &__image\@mobile {
    @include mq($until: desktop) {
      border-radius: 0;
    }
  }

  &__desc-column {
    padding: $space-m $space-m $space-l $space-m;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
  }

  &__desc-column\@desktop {
    @include mq($from: desktop) {
      margin-left: $space-l;
      width: 257px;
      padding: 0;
    }
  }

  &__button {
    margin-top: $space-m;
    flex-shrink: 0;
    width: 100%;

    &-area {
      margin-top: $space-m;
    }
  }

  &__balloon {
    position: relative;
  }

  &__data-container {
    margin-top: $space-s;
    @include mq($from: desktop) {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      width: 100%;
    }
  }

  &__data {
    @include par-xs;
    color: $color-grey-2;
    margin-left: auto;
  }

  .c-button--like {
    max-width: 46px;
    max-height: 46px;
    margin: 0;
  }

  .c-balloon {
    text-align: right;
    position: absolute;
    width: 152px;
    top: 48px;
  }

  .c-balloon--displayable {
    right: 0;
  }

  .c-balloon__inner {
    display: inline-block;
    position: relative;
    font-weight: 600;
    font-size: 10px;
    color: $color-grey-1;
    background: $color-grey-6;
    padding: 8px;

    &::before {
      right: -106px;
      border-bottom-color: $color-grey-6;
    }
  }
}
