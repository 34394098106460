@charset "utf-8";

main {
  background-color: $color-grey-7;
}

.c-mainvisual {
  position: relative;
  display: flex;
  justify-content: center;
  background: #D3DEE3;

  &\@desktop {
    @include mq($from: desktop) {
      height: 480px;
      width: 100%;
      overflow: hidden;
    }
  }

  &__img {
    width: 100%;
  }

  &__img\@desktop {
    @include mq($from: desktop) {
      min-height: 480px;
      background-position: 0% 0;
      height: 100%;
      width: auto;
    }
  }

  &__inner {
    position: relative;
    padding: $space-m;
    margin-top: -140px;
    @include mq($from: desktop) {
      padding: $space-l;
      margin-top: -292px;
      z-index: index($zindex, mainvisual_inner);
    }
  }

  &__inner-txt {
    color: $color-white;
    font-size: 38px;
    font-weight: $font-weight-bold;
    text-align: left;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    @include mq($from: desktop) {
      font-size: 48px;
    }
  }

  &__search {
    position: relative;
    @include mq($from: desktop) {
      margin-top: -160px;
      padding: 0;
    }
  }

  &__btn {
    display: flex;
    font-size: $font-size-l;
    justify-content: center;
    width: 100%;
    line-height: 1;
    padding: $space-m $space-s;
    @include mq($from: desktop) {
      width: 70%;
    }
  }

  &__icon {
    display: block;
    fill: $color-white;
    stroke: $color-white;
    margin: 0 $space-s 0 0;
    @include icon-m;
  }

  &__keyword {
    display: block;
    align-items: center;

    &-title {
      margin-right: $space-l;
      margin-bottom: $space-s;
      @include mq($from: desktop) {
        margin-bottom: 0;
      }
    }
    @include mq($from: desktop) {
      display: flex;
    }
  }
}

@media all and (-ms-high-contrast: none) {

  .c-mainvisual {

    &__toptxt {
      // https://social.msdn.microsoft.com/Forums/ie/en-US/a7808c7d-0178-4b10-b8b1-fbb06dd6ee28/ie-11-div-with-quotborderradiusquot-bug?forum=iewebdevelopment
      border-radius: 0;
    }
  }
}
